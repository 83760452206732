<template>
  <div class="app-container">
    <div class="flex-form">
      <el-form
        v-loading="loading"
        class="info-form"
        label-width="170px"
      >
        <el-form-item>
          <h2>
            View Category
          </h2>
        </el-form-item>

        <el-form-item label="Uid">
          <div class="info-field">
            {{ category.categoryUid }}
          </div>
        </el-form-item>

        <el-form-item label="Name">
          <div class="info-field text-blue">
            {{ category.name }}
          </div>
        </el-form-item>

        <el-form-item label="Relative Url">
          <div class="info-field text-blue">
            {{ category.relativeUrl }}
          </div>
        </el-form-item>

        <el-form-item label="Header Text">
          <div class="info-field text-blue">
            {{ category.headerText }}
          </div>
        </el-form-item>

        <el-form-item label="Content Title">
          <div class="info-field text-blue">
            {{ category.contentTitle }}
          </div>
        </el-form-item>

        <el-form-item label="Content Text">
          <div class="info-field text-blue">
            {{ category.contentText }}
          </div>
        </el-form-item>

        <el-form-item label="Content Text 2">
          <div class="info-field text-blue">
            {{ category.contentText2 }}
          </div>
        </el-form-item>

        <el-form-item label="Active">
          <div class="info-field text-blue">
            {{ category.isActive ? 'Yes': 'No' }}
          </div>
        </el-form-item>

        <el-form-item label="Position">
          <div class="info-field">
            {{ category.position }}
          </div>
        </el-form-item>

        <el-form-item label="Background Color">
          <div class="info-field">
            {{ category.bgColor }}
          </div>
        </el-form-item>

        <el-form-item label="Circle Color">
          <div class="info-field">
            {{ category.circleColor }}
          </div>
        </el-form-item>

        <el-form-item label="Text Color">
          <div class="info-field">
            {{ category.textColor }}
          </div>
        </el-form-item>

        <el-form-item
          v-if="iconUrl"
          label="Icon"
        >
          <div class="info-field">
            <el-image
              style="width: 100px; height: 100px"
              :src="iconUrl"
              fit="scale-down"
            />
          </div>
        </el-form-item>

        <el-form-item
          v-if="logoUrl"
          label="Logo"
        >
          <div class="info-field">
            <el-image
              style="width: 100px; height: 100px"
              :src="logoUrl"
              fit="scale-down"
            />
          </div>
        </el-form-item>

        <el-form-item label="Created At">
          <div class="info-field">
            {{ category.createdAt | formatDate }}
          </div>
        </el-form-item>

        <el-form-item label="Updated At">
          <div class="info-field">
            {{ category.updatedAt | formatDate }}
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import permission from '@/directive/permission';
import { getCategory } from '@/api/category';

export default {
  name: 'CategoryView',
  directives: {
    permission,
  },
  data() {
    return {
      loading: false,
      categoryUid: null,
      category: {},
    };
  },
  computed: {
    iconUrl() {
      return this.category?.media?.icon?.image?.location;
    },
    logoUrl() {
      return this.category?.media?.logo?.image?.location;
    },
  },
  watch: {
    userId() {
      this.getCategory(this.categoryUid);
    },
  },
  created() {
    this.categoryUid = this.$route.params && this.$route.params.id;
    this.getCategory(this.categoryUid);
  },
  activated() {
    this.getCategory(this.categoryUid);
  },
  methods: {
    async getCategory(categoryUid) {
      try {
        this.loading = true;
        this.category = {};

        const data = await getCategory({
          categoryUid,
        });

        this.category = data?.category;
        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.$router.push({
          name: 'CategoryList',
        });
      }
    },
  },
};
</script>
