import Vue from 'vue';

export function listCategories(params) {
  return Vue.$http({
    url: '/categories/listCategories',
    method: 'get',
    params,
  });
}

export function getCategory(params) {
  return Vue.$http({
    url: '/categories/getCategory',
    method: 'get',
    params,
  });
}

export function getAllCategories(params) {
  return Vue.$http({
    url: '/categories/getAllCategories',
    method: 'get',
    params,
  });
}

export function updateCategory(data) {
  return Vue.$http({
    url: '/categories/updateCategory',
    method: 'post',
    data,
  });
}

export function createCategory(data) {
  return Vue.$http({
    url: '/categories/createCategory',
    method: 'post',
    data,
  });
}

export function deleteCategory(data) {
  return Vue.$http({
    url: '/categories/deleteCategory',
    method: 'post',
    data,
  });
}
